import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            stripeAccountId
            stripeStatus
            stripeAccount {
                email
                name
                status
            }
            chargeSystems {
                name
                id
                invoicingInfo {
                    adminId
                    fixedCostPerUser
                    invoicingActivated
                    modifiedTime
                    ChangeHistory {
                        adminId
                        changeTime
                        invoicingActivated
                    }
                }

                groups {
                    privateCharging
                }
                connectors {
                    alias
                }
            }
        }
    }
`;

export const CREATE_STRIPE_ACCOUNT = gql`
    query($organizationId: ID!, $onboarding: Boolean!) {
        createStripeAccount(organizationId: $organizationId, onboarding: $onboarding)
    }
`;

export const GENERATE_STRIPE_LINK = gql`
    query($organizationId: ID!, $onboarding: Boolean!) {
        generateStripeAccountLink(organizationId: $organizationId, onboarding: $onboarding)
    }
`;

export const UPDATE_STRIPE_STATUS = gql`
    mutation UpsertOrganization($id: ID, $stripeStatus: StripeStatus) {
        upsertOrganization(input: { id: $id, stripeStatus: $stripeStatus }) {
            id
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM_INVOICING = gql`
    mutation UpdateInvoicing($id: ID!, $invoicingInfo: InvoicingInfoInput!) {
        chargeSystem(id: $id) {
            updateInvoicingInfo(input: $invoicingInfo) {
                status
            }
        }
    }
`;
