import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                privateCharging
                connectors {
                    id
                    alias
                }
            }
            users {
                id
                name
                lastName
                phoneNumber
                discount
                activeGroupInvoice
                connectors {
                    id
                    data {
                        id
                        alias
                    }
                    accessLevel
                }
                groups {
                    id
                    accessLevel
                    data {
                        id
                        name
                    }
                }
            }
            invitedUsers {
                phoneNumber
                expirationDate
                connectors {
                    id
                    accessLevel
                    data {
                        id
                        alias
                    }
                }
                groups {
                    id
                    accessLevel
                    data {
                        id
                        name
                    }
                }
            }
            invoicingInfo {
                invoicingActivated
            }
        }
    }
`;

export const RESEND_INVITE_USERS = gql`
    mutation ResendInviteUsers($id: ID!, $invites: [UserInviteInput!]!) {
        chargeSystem(id: $id) {
            resendInviteUsers(input: $invites) {
                status
            }
        }
    }
`;

export const REVOKE_INVITED_USERS = gql`
    mutation RevokeInvitedUsers($chargeSystemId: ID!, $invites: [UserInviteInput]!) {
        chargeSystem(id: $chargeSystemId) {
            revokeInvitedUsers(input: $invites) {
                status
            }
        }
    }
`;

export const DELETE_USERS = gql`
    mutation DeleteUsers($chargeSystemId: ID!, $users: [UserInput!]!) {
        chargeSystem(id: $chargeSystemId) {
            deleteUsers(input: $users) {
                status
            }
        }
    }
`;

export const ALTER_ACCESS_USERS = gql`
    mutation AlterAccessUsers($id: ID!, $users: [UserAlterAccessInput!]!) {
        chargeSystem(id: $id) {
            alterAccessUsers(input: $users) {
                status
            }
        }
    }
`;

export const UPDATE_USER_PAYMENT_TYPE = gql`
    mutation UserPaymentType($id: ID!, $userId: ID!, $useInvoicing: Boolean!) {
        chargeSystem(id: $id) {
            userPaymentType(input: { userId: $userId, useInvoicing: $useInvoicing }) {
                status
            }
        }
    }
`;
