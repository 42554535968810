import { gql } from '@apollo/client';

export const SET_DEFAULT = gql`
    query SetDefault($id: ID!) {
        chargeSystem(id: $id) {
            setDefault {
                responseCodes
                status
            }
        }
    }
`;

export const GET_CONNECTORS = gql`
    query ChargeSystemConnectors($id: ID!) {
        chargeSystem(id: $id) {
            connectors {
                id
                alias
                connectorNumber
                status
                type
                operationalData {
                    ocpp {
                        chargingState
                        status
                        version
                    }
                    hbTimeout
                    hbLastAlive
                }
            }
        }
    }
`;
