import React, { useState, useEffect, useRef } from 'react';
import {
    Heading,
    Level,
    Button,
    ActionRow,
    Text,
    ContextWindow,
    TextInput,
    Icon,
    Composite,
    TextType,
    Variant,
    Group,
    ListSection,
    useTheme,
} from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { Connector } from '../../models/connector';
import { Group as GroupModel } from '../../models/group';
import { AccessListItemRoot } from './charger-access-context-window.styles';
import { ConnectorAccess, GroupAccess, UserAccessLevel } from '../../models/user';
import { ChargeSystem } from '../../models/charge-system';
import i18n from '../../i18n';
import { GET_CHARGE_SYSTEM } from './charger-access-context-window.queries';

interface CommonListItem {
    id: string | number;
    name?: string;
    alias?: string;
    subText?: string;
}

function AccessListItem<T extends CommonListItem>(
    item: T,
    itemClick: (item: T, isSelected: boolean) => void,
    isSelected: boolean,
    onOwnerClick?: () => void,
    isOwner?: boolean,
    userCanBeOwner?: boolean
) {
    const theme = useTheme();
    const { id, name, alias, subText } = item;

    const key = encodeURIComponent(name || alias || 'unknown');
    return (
        <Composite key={id}>
            <AccessListItemRoot
                name={`access-item-${key}`}
                onClick={() => itemClick(item, isSelected)}
                variant={Variant.A11Y}
                fillParent
            >
                {isSelected ? (
                    <Icon size={16} color={theme.colorPrimary} icon="checkCircle" />
                ) : (
                    <Icon size={16} icon="boltBox" color={theme.descriptionColor} />
                )}
                <Heading color={isSelected ? theme.colorPrimary : '#000'} level={Level.h4}>
                    {name || alias}
                </Heading>
                <Text
                    type={TextType.description}
                    color={isSelected ? theme.colorPrimary : theme.descriptionColor}
                >
                    {subText}
                </Text>
            </AccessListItemRoot>
            {isSelected && alias && userCanBeOwner && (
                <Button
                    variant={Variant.A11Y}
                    onClick={onOwnerClick}
                    data-test-id={`access-item-${key}-owner`}
                    type="button"
                    fillParent={false}
                >
                    <Icon
                        color={theme.colorPrimary}
                        icon={isOwner ? 'star' : 'starOutline'}
                        size={16}
                    />{' '}
                </Button>
            )}
        </Composite>
    );
}

interface ChargerAccessContextWindowProps {
    positioningElement?: HTMLElement;
    onClosePress: () => void;
    onSubmit: (groups: GroupAccess[], connectors: ConnectorAccess[]) => void;
    userAccess?: { groups: GroupAccess[]; connectors: ConnectorAccess[] };
    zIndex?: number;
    dataset?: { groups: GroupModel[]; connectors: Connector[] };
    defaultAccessLevel?: UserAccessLevel;
    side?: 'left' | 'right';
    userCanBeOwner?: boolean;
}

interface ChargeSystemData {
    chargeSystem: ChargeSystem;
}

export function ChargerAccessContextWindow({
    positioningElement,
    onClosePress,
    onSubmit,
    userAccess,
    zIndex,
    dataset,
    defaultAccessLevel = UserAccessLevel.USER,
    side,
    userCanBeOwner = true,
}: ChargerAccessContextWindowProps) {
    const { id } = useParams<{ id: string }>();

    const [filterText, setFilterText] = useState<string>();

    const [selectedConnectors, setSelectedConnectors] = useState<ConnectorAccess[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<GroupAccess[]>([]);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const searchFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (positioningElement && searchFieldRef?.current) {
            const { groups = [], connectors = [] } = userAccess || {};
            searchFieldRef.current?.focus();
            setSelectedConnectors(connectors);
            setSelectedGroups(groups);
        }
    }, [positioningElement, searchFieldRef, userAccess]);

    const [getChargeSystem, { data }] = useLazyQuery<ChargeSystemData>(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const { chargeSystem } = data || {};
    const { groups = [], connectors = [] } = dataset || chargeSystem || {};

    const onGroupItemClick = (item: GroupModel, isSelected: boolean) => {
        setSelectedGroups((current) => {
            const updatedList = current.filter((c) => c.data.id !== item.id);
            if (!isSelected) {
                updatedList.push({ data: item, accessLevel: defaultAccessLevel });
            }
            return updatedList;
        });
    };

    const onConnectorItemClick = (item: Connector, isSelected: boolean) => {
        setSelectedConnectors((current) => {
            const updatedList = current.filter((c) => c.data.id !== item.id);
            if (!isSelected) {
                updatedList.push({ data: item, accessLevel: UserAccessLevel.USER });
            }
            return updatedList;
        });
    };

    const onFilterTextChange = (text: string) => {
        setFilterText(text.toLowerCase());
    };

    const onAccessModalSubmit = () => {
        if (!submitDisabled) {
            onSubmit(selectedGroups, selectedConnectors);
        }
    };

    const setOwnership = (item: Connector, owner: boolean) => {
        setSelectedConnectors((current) => {
            const accessItemIndex = current.findIndex((c) => c.data.id === item.id);

            if (accessItemIndex !== -1) {
                return [
                    ...current.slice(0, accessItemIndex),
                    {
                        data: item,
                        accessLevel: owner ? UserAccessLevel.OWNER : UserAccessLevel.USER,
                    },
                    ...current.slice(accessItemIndex + 1),
                ];
            }
            return current;
        });
    };

    useEffect(() => {
        if (!dataset) {
            getChargeSystem();
        }
    }, [dataset, getChargeSystem]);

    useEffect(() => {
        const noSelection = selectedConnectors.length === 0 && selectedGroups.length === 0;
        setSubmitDisabled(noSelection);
    }, [selectedConnectors, selectedGroups]);

    return (
        <ContextWindow
            onClosePress={onClosePress}
            show={!!positioningElement}
            positioningElement={positioningElement}
            zIndex={zIndex}
            side={side}
            headerContent={
                <TextInput
                    name="search"
                    label="Search for group or charger"
                    icon="search"
                    onChange={onFilterTextChange}
                    innerRef={searchFieldRef}
                />
            }
            bodyContent={
                <Group>
                    <ListSection<GroupModel>
                        title="Groups"
                        hide={groups.length < 1}
                        items={
                            filterText
                                ? groups.filter((g: GroupModel) =>
                                      g.name.toLowerCase().includes(filterText)
                                  )
                                : groups
                        }
                        renderItem={(item) =>
                            AccessListItem(
                                item,
                                onGroupItemClick,
                                selectedGroups.some((sg) => sg.data.id === item.id)
                            )
                        }
                    />
                    <ListSection<Connector>
                        title="Connectors"
                        hide={connectors.length < 1}
                        actionTitle={
                            selectedConnectors.length > 0 && userCanBeOwner
                                ? 'Set as owner'
                                : undefined
                        }
                        items={
                            filterText
                                ? connectors.filter(
                                      (c: Connector) =>
                                          c.alias.toLowerCase().includes(filterText) ||
                                          c.id.toString().includes(filterText)
                                  )
                                : connectors
                        }
                        renderItem={(item) => {
                            const access = selectedConnectors.find((c) => c.data.id === item.id);
                            const isOwner = access?.accessLevel === 'OWNER';
                            return AccessListItem(
                                item,
                                onConnectorItemClick,
                                !!access,
                                () => setOwnership(item as Connector, !isOwner),
                                access?.accessLevel === 'OWNER',
                                userCanBeOwner
                            );
                        }}
                    />
                </Group>
            }
            actionContent={
                <ActionRow>
                    <Button
                        name="set-access"
                        text={i18n.t('Done')}
                        onClick={onAccessModalSubmit}
                        disabled={submitDisabled}
                    />
                </ActionRow>
            }
        />
    );
}
