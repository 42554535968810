import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Size,
    Text,
    TextType,
    Variant,
} from '@defa/defa-component-library';
import { ChargerDetailItem } from '../charger-details';
import { GET_CONNECTOR_DETAILS } from './connector-details.queries';
import i18n from '../../../i18n';

export function ConnectorDetails({
    onChargerDetailsCloseClick,
}: {
    onChargerDetailsCloseClick: () => void;
}) {
    const { id, connectorAlias } = useParams<{ id: string; connectorAlias: string }>();
    const { data } = useQuery(GET_CONNECTOR_DETAILS, {
        variables: {
            chargeSystemId: id,
            id: connectorAlias,
        },
    });
    const { connector } = data || { connector: {} };

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('ConnectorDetails.Header')}</Heading>
                    <Button
                        name="close-button"
                        icon="close"
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        size={Size.TINY}
                        onClick={onChargerDetailsCloseClick}
                    />
                </Composite>
            </Group>
            <Group>
                <Group>
                    <Composite>
                        <Icon icon="charger2Socket" />
                        <Text type={TextType.description}>
                            {i18n.t('ConnectorDetails.Description')}
                        </Text>
                    </Composite>
                </Group>
                <Group>
                    <Heading level={Level.h4}>{i18n.t('ConnectorDetails.About.Header')}</Heading>
                    <ChargerDetailItem
                        primaryText={i18n.t('ConnectorDetails.About.Alias')}
                        secondaryText={connector?.alias || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ConnectorDetails.About.Id')}
                        secondaryText={connector?.id || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ConnectorDetails.About.ConnectorNumber')}
                        secondaryText={connector?.connectorNumber || ''}
                    />
                </Group>
                <Group>
                    <ChargerDetailItem
                        primaryText={i18n.t('ConnectorDetails.Electricity.Power')}
                        secondaryText={connector?.power || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ConnectorDetails.Electricity.ConnectorType')}
                        secondaryText={connector?.type || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ConnectorDetails.Electricity.TotalEnergyUse')}
                        secondaryText={connector?.meterValue || ''}
                    />
                </Group>
            </Group>
        </>
    );
}
