import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    Button,
    CheckBox,
    Composite,
    Country as CountryCode,
    Dropdown,
    getCurrencyFromCountry,
    Group,
    Heading,
    Level,
    ProgressIndicator,
    ProgressType,
    Suggest,
    SuggestType,
    Text,
    TextInput,
    TextType,
    Variant,
} from '@defa/defa-component-library';

import i18n from '../../i18n';
import {
    GET_COUNTRIES,
    GET_ORGANIZATION_CATEGORIES,
    UPSERT_ORGANIZATION,
    GET_ORGANIZATION,
    OrganizationResponseType,
    CountryResponseType,
    CategoryResponseType,
} from '../onboarding/organization-info/organization-info.queries';
import { useCountry, useCurrency, useOrganization } from '../../utils/hooks';
import { NotificationContext } from '../../utils/notification';
import { LoadingWrapper } from './settings.styles';

export const OrganizationSettings: React.FunctionComponent = () => {
    const { add: addNotification } = useContext(NotificationContext);
    const [organizationId] = useOrganization();
    const [, setCurrency] = useCurrency();
    const [, setCountry] = useCountry();

    const { refetch, loading, error, data } = useQuery<OrganizationResponseType>(GET_ORGANIZATION, {
        variables: {
            id: organizationId,
        },
    });

    const { data: countryData } = useQuery<CountryResponseType>(GET_COUNTRIES);
    const { countries } = countryData || {};

    const { data: categoriesData = {}, loading: categoriesLoading } = useQuery<
        CategoryResponseType
    >(GET_ORGANIZATION_CATEGORIES);
    const { organizationCategories: availableCategories = [] } = categoriesData;

    const [upsertOrganization, { loading: loadingUpsertOrganization }] = useMutation(
        UPSERT_ORGANIZATION
    );

    const {
        name: initialName,
        vatNr: initialVatNr,
        orgNr: initialOrgNr,
        category: initialCategory,
        contactAddress: initialAddress,
        billingAddress: initialBillingAddress,
        vatLiable: initialVatLiable,
        invoiceEmail: initialInvoiceEmail,
        contactEmail: initialContactEmail,
        contactPhone: initialContactPhone,
        id,
        customerNr,
    } = data?.organization || {};

    const [name, setName] = useState<string>();
    const [vatNr, setVatNr] = useState<string>();
    const [orgNr, setOrgNr] = useState<string>();
    const [vatLiable, setVatLiable] = useState<boolean>(false);
    const [invoiceEmail, setInvoiceEmail] = useState<string>();
    const [contactEmail, setContactEmail] = useState<string>();
    const [contactPhone, setContactPhone] = useState<string>();
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [availableCountries, setAvailableCountries] = useState<string[]>([]);
    const [addressDetails, setAddressDetails] = useState<SuggestType>();
    const [billingSameAsAddress, setBillingSameAsAddress] = useState(true);
    const [billingAddress, setBillingAddress] = useState<SuggestType>();

    const { address, zipCode, city, streetNumber } = addressDetails || {};

    const vatOk = vatLiable ? vatNr : true;

    const invalid = !(
        name &&
        vatOk &&
        orgNr &&
        selectedCategory &&
        address &&
        streetNumber &&
        zipCode &&
        city &&
        invoiceEmail &&
        contactEmail &&
        contactPhone &&
        addressDetails?.countryCode &&
        (billingSameAsAddress ||
            (billingAddress?.address &&
                billingAddress?.streetNumber &&
                billingAddress?.zipCode &&
                billingAddress?.city &&
                billingAddress?.countryCode))
    );

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        setName(initialName);
        setVatNr(initialVatNr);
        setOrgNr(initialOrgNr);
        setInvoiceEmail(initialInvoiceEmail);
        setContactEmail(initialContactEmail);
        setContactPhone(initialContactPhone);
        setVatLiable(!!initialVatLiable);
        setSelectedCategory(initialCategory);
        setAvailableCountries(countries?.map((c) => c.id) ?? []);
        setAddressDetails({
            ...initialAddress,
            countryCode: initialAddress?.country,
        });
        setBillingAddress({
            ...initialBillingAddress,
            countryCode: initialBillingAddress?.country,
        });
        setBillingSameAsAddress(
            initialAddress?.address === initialBillingAddress?.address &&
                initialAddress?.zipCode === initialBillingAddress?.zipCode &&
                initialAddress?.city === initialBillingAddress?.city &&
                initialAddress?.country === initialBillingAddress?.country
        );
    }, [
        countries,
        initialAddress,
        initialBillingAddress,
        initialCategory,
        initialName,
        initialVatNr,
        initialOrgNr,
        initialVatLiable,
        initialInvoiceEmail,
        initialContactEmail,
        initialContactPhone,
    ]);

    const updateAddressDetailsPart = (newDetails: Partial<SuggestType>) =>
        setAddressDetails((currentAddressDetails) => ({
            ...currentAddressDetails,
            ...newDetails,
        }));
    const updateBillingAddressPart = (newDetails: Partial<SuggestType>) =>
        setBillingAddress((currentAddressDetails) => ({
            ...currentAddressDetails,
            ...newDetails,
        }));

    const onAddressChanged = (newAddress: string) =>
        updateAddressDetailsPart({ address: newAddress });
    const onStreetNumberChanged = (newStreetNumber: string) =>
        updateAddressDetailsPart({ streetNumber: newStreetNumber });
    const onZipCodeChanged = (newZipCode: string) =>
        updateAddressDetailsPart({ zipCode: newZipCode });
    const onCityChanged = (newCity: string) => updateAddressDetailsPart({ city: newCity });
    const onCountryChanged = (newCountry: string) =>
        updateAddressDetailsPart({ countryCode: newCountry });

    const onSelectAddressChanged = (newSelectedAddress: SuggestType) =>
        setAddressDetails(newSelectedAddress);

    const onBillingSameAsAddressChanged = (checked: boolean) => {
        if (!checked) {
            setBillingAddress(addressDetails);
        }
        setBillingSameAsAddress(checked);
    };

    const onBillingStreetChanged = (newAddress: string) =>
        updateBillingAddressPart({ description: newAddress });
    const onBillingZipCodeChanged = (newZip: string) =>
        updateBillingAddressPart({ zipCode: newZip });
    const onBillingCityChanged = (newCity: string) => updateBillingAddressPart({ city: newCity });
    const onBillingCountryChanged = (newCountry: string) =>
        updateBillingAddressPart({ countryCode: newCountry });
    const onBillingStreetNumberChanged = (newStreetNumber: string) =>
        updateBillingAddressPart({ streetNumber: newStreetNumber });

    const onSelectBillingAddressChanged = (newSelectedAddress: SuggestType) =>
        setBillingAddress(newSelectedAddress);

    const submit = useCallback(() => {
        // Manually mapping fields to get rid of __typename automatically added to result from graphql query.
        const addressToSet = {
            streetNumber: addressDetails?.streetNumber,
            address: addressDetails?.address,
            zipCode: addressDetails?.zipCode,
            city: addressDetails?.city,
            latitude: addressDetails?.latitude,
            longitude: addressDetails?.longitude,
            country: addressDetails?.countryCode,
        };

        const billingAddressToSet = {
            streetNumber: billingAddress?.streetNumber,
            address: billingAddress?.address,
            zipCode: billingAddress?.zipCode,
            city: billingAddress?.city,
            latitude: billingAddress?.latitude,
            longitude: billingAddress?.longitude,
            country: billingAddress?.countryCode,
        };

        upsertOrganization({
            variables: {
                id,
                name,
                invoiceEmail,
                contactEmail,
                contactPhone,
                category: selectedCategory || null,
                vatNr,
                orgNr,
                contactAddress: addressToSet,
                billingAddress: billingSameAsAddress ? addressToSet : billingAddressToSet,
                vatLiable,
            },
        })
            .then(() => {
                if (addressDetails?.countryCode) {
                    setCurrency(getCurrencyFromCountry(addressDetails.countryCode as CountryCode));
                    setCountry(addressDetails.countryCode as CountryCode);
                }
                addNotification({
                    message: i18n.t('OrganizationSettings.Success'),
                });
            })
            .catch(() => {
                addNotification({
                    message: i18n.t('OrganizationSettings.Error'),
                });
            });
    }, [
        addressDetails,
        billingAddress,
        upsertOrganization,
        id,
        name,
        selectedCategory,
        vatNr,
        orgNr,
        billingSameAsAddress,
        vatLiable,
        addNotification,
        setCurrency,
        setCountry,
        invoiceEmail,
        contactEmail,
        contactPhone,
    ]);

    return (
        <Group>
            {error && <p>ERROR...</p>}
            <Group>
                <Heading level={Level.h4}>{i18n.t('OrganizationSettings.Header')}</Heading>
            </Group>
            {loading || categoriesLoading ? (
                <LoadingWrapper>
                    <ProgressIndicator type={ProgressType.DONUT_LOADING} progress={50} />
                </LoadingWrapper>
            ) : (
                <>
                    <Group>
                        <TextInput
                            name="customer-number"
                            label={i18n.t('OrganizationSettings.CustomerNrLabel')}
                            value={customerNr}
                            readOnly
                        />
                        <TextInput
                            name="name"
                            label={i18n.t('OrganizationSettings.OrganizationNameLabel')}
                            value={name}
                            onChange={setName}
                        />
                        <CheckBox
                            name="organizationVAT"
                            label={i18n.t('OrganizationSettings.VATLiable')}
                            checked={vatLiable}
                            onChange={setVatLiable}
                        />
                        {vatLiable && (
                            <TextInput
                                name="vat-number"
                                label={i18n.t('OrganizationSettings.VatNrLabel')}
                                value={vatNr}
                                onChange={setVatNr}
                            />
                        )}
                        <TextInput
                            name="org-number"
                            label={i18n.t('OrganizationSettings.OrgNrLabel')}
                            value={orgNr}
                            onChange={setOrgNr}
                        />
                        <TextInput
                            name="contact-email"
                            label={i18n.t('OrganizationSettings.ContactEmailLabel')}
                            value={contactEmail}
                            onChange={setContactEmail}
                        />
                        <TextInput
                            name="contact-phone"
                            label={i18n.t('OrganizationSettings.ContactPhoneLabel')}
                            value={contactPhone}
                            onChange={setContactPhone}
                        />
                        <TextInput
                            name="invoice-email"
                            label={i18n.t('OrganizationSettings.InvoiceEmailLabel')}
                            value={invoiceEmail}
                            onChange={setInvoiceEmail}
                        />

                        <Dropdown<string>
                            name="category"
                            label={i18n.t('OrganizationSettings.CategoryLabel')}
                            placeholder={i18n.t('OrganizationSettings.CategoryPlaceholder')}
                            onChange={setSelectedCategory}
                            value={selectedCategory}
                            items={availableCategories}
                            keyExtractor={(item: string) => item}
                            labelExtractor={(item: string) =>
                                i18n.t(`OrganizationSettings.Category.${item}`)
                            }
                        />
                    </Group>
                    <Group>
                        <Group>
                            <Text type={TextType.descriptionBold}>
                                {i18n.t('OrganizationSettings.AddressHeader')}
                            </Text>
                            <Composite fillParent>
                                <Suggest
                                    name="street-address"
                                    label={i18n.t('SetUpOrganization.StreetAddressLabel')}
                                    value={address}
                                    onChange={onAddressChanged}
                                    onSelect={onSelectAddressChanged}
                                    country={addressDetails?.countryCode}
                                />

                                <TextInput
                                    name="streetNumber"
                                    label={i18n.t('SetUpOrganization.StreetNumberLabel')}
                                    value={streetNumber}
                                    onChange={onStreetNumberChanged}
                                    flex="0 1"
                                />
                            </Composite>
                            <Composite>
                                <TextInput
                                    name="zip-code"
                                    label={i18n.t('OrganizationSettings.ZipCodeLabel')}
                                    value={zipCode}
                                    onChange={onZipCodeChanged}
                                    flex="0 1"
                                />
                                <TextInput
                                    name="city"
                                    label={i18n.t('OrganizationSettings.CityLabel')}
                                    value={city}
                                    onChange={onCityChanged}
                                />
                            </Composite>
                            <Dropdown<string>
                                name="country"
                                label={i18n.t('OrganizationSettings.CountryLabel')}
                                placeholder={i18n.t('OrganizationSettings.CountryPlaceholder')}
                                onChange={onCountryChanged}
                                value={availableCountries.find(
                                    (countryCode: string) =>
                                        countryCode === addressDetails?.countryCode
                                )}
                                items={availableCountries}
                                keyExtractor={(countryCode: string) => countryCode}
                                labelExtractor={(countryCode: string) =>
                                    i18n.t(`Countries.${countryCode}`)
                                }
                            />
                        </Group>
                    </Group>
                    <Group>
                        <Composite>
                            <Text type={TextType.descriptionBold}>
                                {i18n.t('OrganizationSettings.BillingAddressHeader')}
                            </Text>
                            <CheckBox
                                name="same-as-address"
                                label={i18n.t('OrganizationSettings.SameAsAddressLabel')}
                                fillContainer={false}
                                checked={billingSameAsAddress}
                                onChange={onBillingSameAsAddressChanged}
                            />
                        </Composite>
                        {!billingSameAsAddress && (
                            <>
                                <Composite>
                                    <Suggest
                                        name="billing-street-address"
                                        label={i18n.t('SetUpOrganization.StreetAddressLabel')}
                                        value={billingAddress?.address}
                                        onChange={onBillingStreetChanged}
                                        onSelect={onSelectBillingAddressChanged}
                                        country={billingAddress?.countryCode}
                                    />
                                    <TextInput
                                        name="streetNumber"
                                        label={i18n.t('SetUpOrganization.StreetNumberLabel')}
                                        value={billingAddress?.streetNumber}
                                        onChange={onBillingStreetNumberChanged}
                                        flex="0 1"
                                    />
                                </Composite>
                                <Composite>
                                    <TextInput
                                        name="billing-zip-code"
                                        label={i18n.t('OrganizationSettings.ZipCodeLabel')}
                                        value={billingAddress?.zipCode}
                                        onChange={onBillingZipCodeChanged}
                                        flex="0 1"
                                    />
                                    <TextInput
                                        name="billing-city"
                                        label={i18n.t('OrganizationSettings.CityLabel')}
                                        value={billingAddress?.city}
                                        onChange={onBillingCityChanged}
                                    />
                                </Composite>
                                <Dropdown<string>
                                    name="billing-country"
                                    label={i18n.t('OrganizationSettings.CountryLabel')}
                                    placeholder={i18n.t('OrganizationSettings.CountryPlaceholder')}
                                    onChange={onBillingCountryChanged}
                                    value={availableCountries.find(
                                        (countryCode: string) =>
                                            countryCode === billingAddress?.countryCode
                                    )}
                                    items={availableCountries}
                                    keyExtractor={(countryCode: string) => countryCode}
                                    labelExtractor={(countryCode: string) =>
                                        i18n.t(`Countries.${countryCode}`)
                                    }
                                />
                            </>
                        )}
                    </Group>
                </>
            )}
            <Button
                name="submit-button"
                text={i18n.t('Save')}
                variant={Variant.PRIMARY}
                disabled={invalid || loading || categoriesLoading}
                loading={loadingUpsertOrganization}
                onClick={submit}
            />
        </Group>
    );
};
