import { gql } from '@apollo/client';
import { ChargeSystem } from '../../models';

export interface ShallowOrganization {
    id: string;
    chargeSystems: [{ id: string }];
}

export interface ChargeSystemResponseType {
    chargeSystem: ChargeSystem;
    organizations: ShallowOrganization[];
}

export const CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            name
            configurationStatus
            addressDetails {
                streetNumber
                address
                zipCode
                city
                country
            }
            groups {
                id
                name
                connectors {
                    id
                    alias
                    status
                    type
                    operationalData {
                        ocpp {
                            chargingState
                            status
                            version
                        }
                        hbTimeout
                        hbLastAlive
                    }
                }
                visibleInApps
                broadcast
                privateCharging
                publicCharging
                privateChargingSettings {
                    priceModel
                    priceModelAmount
                }
                publicChargingSettings {
                    priceModel
                    priceModelAmount
                }
            }
        }
        organizations {
            id
            chargeSystems {
                id
            }
        }
    }
`;
