import { gql } from '@apollo/client';

export const GET_CONNECTOR_DETAILS = gql`
    query Connectors($chargeSystemId: ID!, $id: ID!) {
        connector(chargeSystemId: $chargeSystemId, id: $id) {
            id
            alias
            connectorNumber
            meterValue
            power
            type
        }
    }
`;
