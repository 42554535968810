/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Chip, Size, useTheme } from '@defa/defa-component-library';
import React from 'react';
import i18n from '../../i18n';
import { Admin, ChargeSystemAccess, ConnectorAccess, GroupAccess, User } from '../../models';

export function AccessPills<
    K extends {
        connectors?: ConnectorAccess[];
        groups?: GroupAccess[];
        chargeSystems?: ChargeSystemAccess[];
        id: string | number;
        email?: string;
        phoneNumber?: string;
    }
>(onClick: (e: React.MouseEvent, data: K) => void) {
    return (user: K) => {
        const { connectors = [], groups = [], chargeSystems = [] } = user;
        const currentAccess = [
            ...chargeSystems.map((cs) => cs.data?.name),
            ...groups.map((g) => g.data?.name),
            ...connectors.map((c) => c.data?.alias),
        ];

        const { id, email, phoneNumber } = user;
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                style={{ alignItems: 'center', display: 'flex' }}
                key={`${encodeURIComponent(email || phoneNumber || id)}-pills`}
                data-test-id={`access-${encodeURIComponent(email || phoneNumber || id)}-pills`}
                onClick={(e) => onClick(e, user)}
            >
                {currentAccess.length > 0 && <Chip>{currentAccess[0]}</Chip>}
                {currentAccess.length > 1 && <Chip>+{currentAccess.slice(1).length}</Chip>}
            </div>
        );
    };
}

export function AccessListItem(data: Admin | User) {
    const { id, email, phoneNumber, name, lastName } = data;

    return (
        <Avatar
            key={`${encodeURIComponent(email || phoneNumber || id)}-avatar-access`}
            user={{
                name: `${name ?? ''} ${lastName ?? ''}`,
                email,
                phoneNumber: `+${phoneNumber}`,
            }}
            tid={`access-${encodeURIComponent(email || phoneNumber || id)}`}
            size={Size.SMALL}
        />
    );
}

export function PaymentTypeListItem(data: User) {
    const { id, name, activeGroupInvoice } = data;
    return (
        <span key={`${encodeURIComponent(id)}-payment-type`} title={`${id} - ${name}`}>
            {i18n.t(`Users.PaymentType.${activeGroupInvoice ? 'INVOICE' : 'CARD'}`)}
        </span>
    );
}

export function InviteListItem(data: Admin | User) {
    const theme = useTheme();
    const { id, email, phoneNumber, expirationDate } = data;
    const numberOfDaysLeft =
        expirationDate &&
        Math.round((new Date(expirationDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));

    return (
        <Avatar
            key={`${encodeURIComponent(email || phoneNumber || id)}-avatar-invite`}
            user={{
                email,
                phoneNumber: `+${phoneNumber}`,
                expirationDate: i18n.t('AccessListItem.ExpirationLabel', { numberOfDaysLeft }),
            }}
            tid={`invite-${encodeURIComponent(email || phoneNumber || id)}`}
            size={Size.SMALL}
            secondaryTextColor={theme.avatarSecondaryColor}
        />
    );
}
