import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                privateCharging
                connectors {
                    id
                    alias
                }
            }
            rfidKeys {
                id
                name
                keys
                connectors {
                    id
                    accessLevel
                    data {
                        id
                        alias
                    }
                }
                groups {
                    id
                    accessLevel
                    data {
                        id
                        name
                    }
                }
            }
        }
    }
`;
