import React from 'react';
import { SideMenu, useTheme } from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';
import i18n from '../../i18n';
import { SelectableSettingsSideMenuItem } from '../group-settings-side-menu';

export function ChargeSystemSettingsSideMenu() {
    const { id: chargeSystemId } = useParams<{ id: string }>();
    const rootUrl = `/charge-system/${chargeSystemId}/settings`;
    const theme = useTheme();
    return (
        <SideMenu
            headerText={i18n.t('ChargeSystemSettings.SideMenuHeader')}
            width="max-content"
            padding={`${theme.spacing(5)} ${theme.spacing(4)}`}
            background={theme.sideMenuAlternativeBackground}
        >
            <SelectableSettingsSideMenuItem
                tid="charge-system-settings-side-menu-about"
                icon="information"
                text={i18n.t('ChargeSystemSettings.About')}
                href={`${rootUrl}/about`}
            />
            <SelectableSettingsSideMenuItem
                tid="charge-system-settings-side-menu-sms"
                icon="mobile"
                text={i18n.t('ChargeSystemSettings.SmsAlerts')}
                href={`${rootUrl}/sms`}
            />
            <SelectableSettingsSideMenuItem
                tid="charge-system-settings-side-menu-users"
                icon="privateCharging"
                text={i18n.t('ChargeSystemSettings.Users')}
                href={`${rootUrl}/users`}
            />
            <SelectableSettingsSideMenuItem
                tid="charge-system-settings-side-menu-keys"
                icon="chargingStation"
                text={i18n.t('ChargeSystemSettings.Keys')}
                href={`${rootUrl}/keys`}
            />
        </SideMenu>
    );
}
