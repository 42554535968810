import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Button,
    Card,
    Column,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Size,
    SimpleTable,
    Text,
    TextType,
    Variant,
    ProgressIndicator,
    ProgressType,
    ContextWindow,
    useTheme,
    DatePicker,
    getColorByInitials,
    formatNumberByLocale,
    getCurrencyFormattedCost,
    Currency,
} from '@defa/defa-component-library';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header, MenuItem } from '../../fractions/header';
import { EnergyConsumptionChart } from './energy-consumption-chart';
import {
    ChartOneSectionWrapper,
    ChartTwoSectionWrapper,
    Content,
    InfoBoxRoot,
    Root,
    TableSectionWrapper,
    ChartContainer,
} from './reports.styles';
import { RevenueChart } from './revenue-chart';
import i18n from '../../i18n';
import { generateCsv } from '../../utils/csv';
import { useOrganization } from '../../utils/hooks';
import {
    CHARGING_SESSIONS,
    GENERATE_STRIPE_LINK,
    GET_ORGANIZATION,
    REPORTS,
    REPORTS_AVAILABLE,
} from './reports.queries';
import {
    ChargerUsage,
    UserUsage,
    VisitorRfidUsage,
    SessionItem,
    ReportData,
    ReportDataAvailable,
    ChargingSessionData,
    StripeAccountLinkData,
    ReportPerDay,
} from './reports.types';
import { UIState } from '../../models/ui';
import { StripeStatus as STRIPE_STATUS } from '../../models/organization';
import { getDefaultReportsPathForChargeSystem } from './reports-redirect';

export const getLocalDay = (day: string) => {
    const date = new Date(day);
    // This solution will not work with different time zones since January 1st in GMT
    // is December 31st in EST. Should be fixed when we implement internalization e.g. date-fns.
    return date.toDateString();
};

const localDecimals = (amount: number, minAmount: number = 1000, decimals: number = 3) => {
    if (navigator.language && amount) {
        return formatNumberByLocale(navigator.language, amount, amount > minAmount ? 0 : decimals);
    }
    return 0;
};

const localCurrency = (amount: number, currency: Currency) => {
    const theAmount = amount ?? 0;
    try {
        if (navigator.language && currency) {
            return getCurrencyFormattedCost(
                navigator.language,
                currency,
                theAmount,
                theAmount > 1000 ? 0 : 2
            ).normalize('NFKC');
        }
    } catch (e) {
        console.error('localCurrency fail', currency);
    }
    return 0;
};

export const ChargingSessionsDataButon = ({
    id,
    year,
    month,
    sessionsColumnNames,
    tid,
    currency,
}: {
    id: any;
    year: string;
    month: string;
    sessionsColumnNames: string[];
    tid?: string;
    currency: Currency;
}) => {
    const [getChargingSessions] = useLazyQuery<ChargingSessionData>(CHARGING_SESSIONS);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const downloadChargingSessionsCsv = async () => {
        setLoading(true);
        const { data } = await getChargingSessions({
            variables: {
                id,
                year,
                month,
            },
        });

        const { sessions } = data?.chargeSystem || {};
        const sessionRows = [sessionsColumnNames];
        sessions?.forEach((item) =>
            sessionRows.push([
                item.name,
                item.facility,
                item.user,
                item.phone,
                item.keyId,
                item.chargerID,
                `${item.connectorID}`,
                item.startChargingDate,
                item.startChargingTime,
                item.endChargingDate,
                item.endChargingTime,
                `${localDecimals(item.sessionTimeMin ?? 0, 100, 1)}`,
                `${localDecimals(item.energyConsumption ?? 0)}`,
                `${localCurrency(item.sessionAmountIncVAT ?? 0, currency)}`,
                `${localCurrency(item.sessionAmountExVAT ?? 0, currency)}`,
                `${localCurrency(item.vatAmount ?? 0, currency)}`,
                item.paymentProvider,
                item.paymentStatus,
                `${localCurrency(item.cloudChargeTransactionFee ?? 0, currency)}`,
                `${localCurrency(item.payoutAmount ?? 0, currency)}`,
                item.stripeTransactionID ?? '',
            ])
        );
        generateCsv(sessionRows, `Charge sessions ${id} ${year}-${month}.csv`);
        setLoading(false);
    };

    return (
        <MenuItem
            tid={tid}
            color={theme.textColor}
            icon="arrowDown"
            text={i18n.t('Reports.ChargingSessionsContextMenuItemText')}
            onClick={downloadChargingSessionsCsv}
            disabled={loading}
        />
    );
};

function ChargeSystemAvatar({ description }: { description: string }) {
    const theme = useTheme();
    const initials = description?.substring(0, 2) ?? '??';
    const color = getColorByInitials(initials, theme);
    return (
        <InfoBoxRoot color={color}>
            <Heading level={Level.h4}>{initials.toLocaleUpperCase()}</Heading>
        </InfoBoxRoot>
    );
}

function ChargeSystemSelection({
    activeId,
    chargeSystems,
    onChangeSystem,
}: {
    activeId: string;
    chargeSystems: any;
    onChangeSystem: (cs: string) => void;
}) {
    const theme = useTheme();
    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );

    const activeChargeSystem = chargeSystems.find((cs: any) => cs.id === activeId);

    return (
        <>
            <Button
                fillParent={false}
                iconAfter="chevron"
                iconUseMargin
                variant={Variant.TEXT}
                padding="0"
                onClick={(e?: MouseEvent) => {
                    const { target } = e || ({} as MouseEvent);
                    setMenuPositioningElement(
                        menuPositioningElement ? undefined : (target as HTMLElement)
                    );
                }}
                showInPrint
            >
                <Composite justify="end">
                    <ChargeSystemAvatar description={activeChargeSystem?.name ?? 'NN'} />
                    <Heading level={Level.h3}>{activeChargeSystem?.name ?? 'NN'}</Heading>
                </Composite>
            </Button>
            <ContextWindow
                positioningElement={menuPositioningElement}
                onClosePress={() => {
                    setMenuPositioningElement(undefined);
                }}
                show={!!menuPositioningElement}
                autoHeight
                side="right"
                bodyContent={chargeSystems?.map((cs: any) => (
                    <MenuItem
                        key={cs.id}
                        color={cs.id === activeId ? theme.colorPrimary : theme.textColor}
                        avatar={<ChargeSystemAvatar description={cs.name} />}
                        text={cs.name}
                        onClick={() => {
                            onChangeSystem(cs.id);
                            setMenuPositioningElement(undefined);
                        }}
                    />
                ))}
            />
        </>
    );
}

export const Reports: React.FunctionComponent = () => {
    const { year, month, chargeSystemId } = useParams<{
        year: string;
        month: string;
        chargeSystemId: string;
    }>();
    const history = useHistory();
    const theme = useTheme();
    const [organizationId] = useOrganization();
    const { data } = useQuery(GET_ORGANIZATION, {
        variables: {
            id: organizationId,
        },
    });
    const { organization } = data || { organization: { chargeSystems: [] } };
    const [getReport, { data: reportsData, error }] = useLazyQuery<ReportData>(REPORTS);
    const [getReportAvailable, { data: reportsDataAvailable }] = useLazyQuery<ReportDataAvailable>(
        REPORTS_AVAILABLE
    );
    const [calendarDate, setCalendarDate] = useState<Date>(new Date());
    const [uiState, setUiState] = useState<UIState>(UIState.LOADING);
    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );
    const [generateLink, { data: linkData }] = useMutation<StripeAccountLinkData>(
        GENERATE_STRIPE_LINK
    );
    const defaultOrg = React.useMemo(() => ({}), []);
    const { stripeStatus, stripeAccountId, vatLiable } = organization || defaultOrg;
    const { organizations: orgsForLink } = linkData || {};
    const [orgForLink] = orgsForLink || [];
    const { generateStripeAccountLink } = orgForLink || organization;

    useEffect(() => {
        if (generateStripeAccountLink && stripeStatus === STRIPE_STATUS.ENABLED) {
            window.open(generateStripeAccountLink);
        }
    }, [generateStripeAccountLink, stripeStatus]);

    const getReportQuery = useCallback(() => {
        setUiState(UIState.LOADING);
        getReport({
            variables: {
                id: chargeSystemId,
                year: calendarDate.getFullYear().toString(),
                month: (calendarDate.getMonth() + 1).toString(),
            },
        })
            .then(() => setUiState(UIState.READY))
            .catch(() => setUiState(UIState.ERROR));
    }, [chargeSystemId, getReport, calendarDate]);

    const getReportAvailability = useCallback(() => {
        setUiState(UIState.LOADING);
        getReportAvailable({
            variables: {
                id: chargeSystemId,
            },
        })
            .then(() => setUiState(UIState.READY))
            .catch(() => setUiState(UIState.ERROR));
    }, [chargeSystemId, getReportAvailable]);

    useEffect(() => {
        if (year && month) {
            setCalendarDate(new Date(`${year}-${month}-01`));
        }
    }, [year, month]);

    useEffect(() => {
        getReportQuery();
        getReportAvailability();
    }, [getReportQuery, getReportAvailability]);

    useEffect(() => {
        if (error) {
            setUiState(UIState.ERROR);
        }
    }, [error]);

    useEffect(() => (reportsData ? setUiState(UIState.READY) : setUiState(UIState.LOADING)), [
        reportsData,
    ]);

    const { chargeSystem } = reportsData || {};
    const { report } = chargeSystem || {};

    const chargerUsage = [...(report?.data.chargerUsage ?? [])].sort((a, b) =>
        a?.alias?.localeCompare(b?.alias, navigator.language)
    );

    const defaultCurency =
        report?.data.chargerUsage.find((c) => c?.revenue?.unit.toString() !== '')?.revenue?.unit ||
        null;

    const userUsage = [...(report?.data.userUsage ?? [])].sort((a, b) =>
        a?.user?.localeCompare(b?.user, navigator.language)
    );

    const rfidUsage = [...(report?.data.visitorRfidUsage ?? [])].sort((a, b) =>
        a?.keyId?.localeCompare(b?.keyId, navigator.language)
    );

    type ExcludesFalse = <T>(x: T | undefined) => x is T;
    const chargerUsageColumns = [
        Column('id'),
        Column('sessions'),
        Column('totalTime'),
        Column('totalEnergy'),
        stripeStatus === STRIPE_STATUS.ENABLED ? Column('revenue') : undefined,
    ].filter((Boolean as any) as ExcludesFalse);

    const userUsageColumns = [
        Column('user'),
        Column('phone'),
        Column('sessions'),
        Column('totalTime'),
        Column('totalEnergy'),
        stripeStatus === STRIPE_STATUS.ENABLED ? Column('revenue') : undefined,
    ].filter((Boolean as any) as ExcludesFalse);

    const visitorRfidUsageColumns = [
        Column('keyId'),
        Column('description'),
        Column('sessions'),
        Column('totalTime'),
        Column('totalEnergy'),
    ];

    const chargerUsageColumnNames = [
        i18n.t('Reports.TableSection.ID'),
        i18n.t('Reports.TableSection.Sessions'),
        i18n.t('Reports.TableSection.TotalTime'),
        i18n.t('Reports.TableSection.TotalEnergy'),
        stripeStatus === STRIPE_STATUS.ENABLED ? i18n.t('Reports.TableSection.Revenue') : '',
    ].filter(Boolean);

    const userUsageColumnNames = [
        i18n.t('Reports.TableSection.User'),
        i18n.t('Reports.TableSection.Phone'),
        i18n.t('Reports.TableSection.Sessions'),
        i18n.t('Reports.TableSection.TotalTime'),
        i18n.t('Reports.TableSection.TotalEnergy'),
        stripeStatus === STRIPE_STATUS.ENABLED ? i18n.t('Reports.TableSection.Revenue') : '',
    ].filter(Boolean);

    const visitorRfidUsageColumnNames = [
        i18n.t('Reports.TableSection.KeyId'),
        i18n.t('Reports.TableSection.Description'),
        i18n.t('Reports.TableSection.Sessions'),
        i18n.t('Reports.TableSection.TotalTime'),
        i18n.t('Reports.TableSection.TotalEnergy'),
    ];

    const sessionsColumnNames = [
        i18n.t('Reports.TableSection.ChargeSystem'),
        i18n.t('Reports.TableSection.ChargeSystemId'),
        i18n.t('Reports.TableSection.User'),
        i18n.t('Reports.TableSection.Phone'),
        i18n.t('Reports.TableSection.KeyId'),
        i18n.t('Reports.TableSection.ChargerID'),
        i18n.t('Reports.TableSection.ConnectorID'),
        i18n.t('Reports.TableSection.StartChargingDate'),
        i18n.t('Reports.TableSection.StartChargingTime'),
        i18n.t('Reports.TableSection.EndChargingDate'),
        i18n.t('Reports.TableSection.EndChargingTime'),
        i18n.t('Reports.TableSection.SessionTime'),
        i18n.t('Reports.TableSection.Energy', { unit: report?.total?.energyConsumption?.unit }),
        i18n.t('Reports.TableSection.SessionAmountIncVAT'),
        i18n.t('Reports.TableSection.SessionAmountExVAT'),
        i18n.t('Reports.TableSection.VatAmount'),
        i18n.t('Reports.TableSection.PaymentProvider'),
        i18n.t('Reports.TableSection.PaymentStatus'),
        i18n.t('Reports.TableSection.CloudChargeTransactionFee'),
        i18n.t('Reports.TableSection.PayoutAmount'),
        i18n.t('Reports.TableSection.PaymentIntentID'),
    ];

    const downloadChargerUsageCsv = () => {
        const chargerUsageCSVColumnNames = [
            i18n.t('Reports.TableSection.ID'),
            i18n.t('Reports.TableSection.Sessions'),
            `${i18n.t('Reports.TableSection.TotalTime')} (${report?.total?.occupiedTime?.unit})`,
            `${i18n.t('Reports.TableSection.TotalEnergy')} (${
                report?.total?.energyConsumption?.unit
            })`,
            stripeStatus === STRIPE_STATUS.ENABLED
                ? `${i18n.t('Reports.TableSection.Revenue')} (${
                      report?.total?.revenue?.unit
                  } ${i18n.t(
                      vatLiable
                          ? 'Reports.TableSection.ExcludeVAT'
                          : 'Reports.TableSection.IncludeVAT'
                  )})`
                : '',
        ].filter(Boolean);
        const rows = [chargerUsageCSVColumnNames];
        report?.data?.chargerUsage.map((item: ChargerUsage) =>
            rows.push(
                [
                    item.alias,
                    `${item.chargingSessions.amount}`,
                    `${localDecimals(item.occupiedTime.amount ?? 0, 100, 1)}`,
                    `${localDecimals(item.energyConsumption.amount)}`,
                    stripeStatus === STRIPE_STATUS.ENABLED
                        ? `${localCurrency(
                              item.revenue.amount,
                              item.revenue.unit || defaultCurency
                          )}`
                        : '',
                ].filter(Boolean)
            )
        );
        generateCsv(
            rows,
            `Charger details ${chargeSystemId} ${calendarDate.getFullYear().toString()}-${(
                calendarDate.getMonth() + 1
            ).toString()}.csv`
        );
    };

    const downloadUserUsageCsv = () => {
        const columnNames = [
            i18n.t('Reports.TableSection.User'),
            i18n.t('Reports.TableSection.Phone'),
            i18n.t('Reports.TableSection.Sessions'),
            `${i18n.t('Reports.TableSection.TotalTime')}`,
            `${i18n.t('Reports.TableSection.TotalEnergy')}`,
            stripeStatus === STRIPE_STATUS.ENABLED
                ? `${i18n.t('Reports.TableSection.Revenue')} (${
                      report?.total?.revenue?.unit
                  } ${i18n.t(
                      vatLiable
                          ? 'Reports.TableSection.ExcludeVAT'
                          : 'Reports.TableSection.IncludeVAT'
                  )})`
                : '',
        ].filter(Boolean);
        const rows = [columnNames];
        report?.data?.userUsage.map((item: UserUsage) =>
            rows.push(
                [
                    `${item.user}`,
                    `${item.phone}`,
                    `${item.chargingSessions.amount}`,
                    `${localDecimals(item.occupiedTime.amount ?? 0, 100, 1)}`,
                    `${localDecimals(item.energyConsumption.amount)}`,
                    stripeStatus === STRIPE_STATUS.ENABLED
                        ? `${localCurrency(
                              item.revenue.amount,
                              item.revenue.unit || defaultCurency
                          )}`
                        : '',
                ].filter(Boolean)
            )
        );
        generateCsv(
            rows,
            `User details ${chargeSystemId} ${calendarDate.getFullYear().toString()}-${(
                calendarDate.getMonth() + 1
            ).toString()}.csv`
        );
    };

    const downloadVisitorRfidUsageCsv = () => {
        const columnNames = [
            i18n.t('Reports.TableSection.KeyId'),
            i18n.t('Reports.TableSection.Description'),
            i18n.t('Reports.TableSection.Sessions'),
            `${i18n.t('Reports.TableSection.TotalTime')} (${report?.total?.occupiedTime?.unit})`,
            `${i18n.t('Reports.TableSection.TotalEnergy')} (${
                report?.total?.energyConsumption?.unit
            })`,
        ];
        const rows = [columnNames];
        report?.data?.visitorRfidUsage.map((item: VisitorRfidUsage) =>
            rows.push([
                `${item.keyId}`,
                `${item.description}`,
                `${item.chargingSessions.amount}`,
                `${localDecimals(item.occupiedTime.amount ?? 0, 100, 1)}`,
                `${localDecimals(item.energyConsumption.amount)}`,
            ])
        );
        generateCsv(
            rows,
            `Visitor Rfid details ${chargeSystemId} ${calendarDate.getFullYear().toString()}-${(
                calendarDate.getMonth() + 1
            ).toString()}.csv`
        );
    };

    const onCalendarDateChange = useCallback(
        (date: Date) =>
            history.push(
                `/reports/${chargeSystemId}/${date.getFullYear().toString()}/${(
                    date.getMonth() + 1
                ).toString()}`
            ),
        [chargeSystemId, history]
    );

    const onChargeSystemChange = (newId: string) =>
        history.push(getDefaultReportsPathForChargeSystem(newId));

    return (
        <Root>
            <Header />
            <Group verticalMargin="40px">
                <Composite fillParent>
                    <ChargeSystemSelection
                        activeId={chargeSystemId}
                        chargeSystems={organization?.chargeSystems}
                        onChangeSystem={onChargeSystemChange}
                    />
                    <Composite justify="end">
                        <DatePicker
                            value={calendarDate}
                            maxDate={new Date()}
                            minDate={
                                reportsDataAvailable?.chargeSystem?.reportAvailable?.year
                                    ? new Date(
                                          `${reportsDataAvailable.chargeSystem.reportAvailable.year}-${reportsDataAvailable.chargeSystem.reportAvailable.month}-01`
                                      )
                                    : new Date()
                            }
                            onChange={onCalendarDateChange}
                            showInPrint
                        />
                        <Button
                            name="download-pdf-button"
                            size={Size.SMALL}
                            variant={Variant.SECONDARY}
                            fillParent={false}
                            iconAfter="arrowDown"
                            iconUseMargin
                            onClick={() => {
                                window.print();
                            }}
                        >
                            {i18n.t('Reports.DownloadPdfButton')}
                        </Button>
                    </Composite>
                </Composite>
            </Group>
            <Content>
                <ChartOneSectionWrapper>
                    <Card
                        clickable={false}
                        size={Size.LARGE}
                        loading={uiState === UIState.LOADING}
                        alignChildren="center"
                        justifyChildren="center"
                        error={uiState === UIState.ERROR}
                        errorButtonText={i18n.t('Reports.ReloadButtonText')}
                        errorButtonOnClick={getReportQuery}
                        title={
                            <Composite fillParent align="start">
                                <div>
                                    <Heading level={Level.h4}>
                                        {i18n.t('Reports.EnergyConsumptionTitle')}
                                    </Heading>
                                    {uiState === UIState.READY && (
                                        <Composite align="start" justify="start">
                                            <Heading level={Level.headline}>
                                                {report &&
                                                    localDecimals(
                                                        report?.total?.energyConsumption?.amount
                                                    )}
                                            </Heading>
                                            <Text type={TextType.body}>
                                                {report?.total?.energyConsumption?.unit}
                                            </Text>
                                        </Composite>
                                    )}
                                </div>
                                {uiState === UIState.READY && (
                                    <div>
                                        <Composite gap={0} justify="end">
                                            {report &&
                                            report?.trend?.energyConsumption?.amount >= 0 ? (
                                                <Icon
                                                    size={16}
                                                    icon="arrowUp"
                                                    color={theme.successColor}
                                                />
                                            ) : (
                                                <Icon
                                                    size={16}
                                                    icon="arrowDown"
                                                    color={theme.errorColor}
                                                />
                                            )}
                                            <Text
                                                color={
                                                    report &&
                                                    report?.trend?.energyConsumption?.amount >= 0
                                                        ? theme.successColor
                                                        : theme.errorColor
                                                }
                                            >
                                                {report?.trend?.energyConsumption?.amount.toFixed(
                                                    2
                                                )}
                                                {report?.trend?.energyConsumption?.unit}
                                            </Text>
                                        </Composite>
                                        <Text
                                            type={TextType.descriptionSmall}
                                            color={theme.disabledColor}
                                        >
                                            {i18n.t('Reports.TrendDescription')}
                                        </Text>
                                    </div>
                                )}
                            </Composite>
                        }
                    >
                        <ChartContainer>
                            <EnergyConsumptionChart
                                label={i18n.t('Reports.EnergyConsumptionTitle')}
                                id="EnergyConsumption"
                                datalabels={
                                    report
                                        ? report.perDay.energyConsumption.map(
                                              (_: ReportPerDay, i: number) => (i + 1).toString()
                                          )
                                        : []
                                }
                                datapoints={
                                    report
                                        ? report.perDay.energyConsumption.map(
                                              (item: ReportPerDay) => item.amount
                                          )
                                        : []
                                }
                                dates={
                                    report
                                        ? report.perDay.energyConsumption.map(
                                              (item: ReportPerDay) => item.date
                                          )
                                        : []
                                }
                                unit={(report && report.perDay.energyConsumption[0].unit) ?? ''}
                            />
                        </ChartContainer>
                    </Card>
                </ChartOneSectionWrapper>
                <ChartTwoSectionWrapper>
                    {stripeStatus === STRIPE_STATUS.ENABLED && (
                        <Card
                            clickable={false}
                            size={Size.LARGE}
                            loading={uiState === UIState.LOADING}
                            alignChildren="start"
                            justifyChildren="start"
                            error={uiState === UIState.ERROR}
                            errorButtonText={i18n.t('Reports.ReloadButtonText')}
                            errorButtonOnClick={getReportQuery}
                            title={
                                <Composite fillParent align="start">
                                    <div>
                                        <Heading level={Level.h4}>
                                            {i18n.t('Reports.RevenueTitle')}
                                        </Heading>
                                        {uiState === UIState.READY && (
                                            <>
                                                <Composite align="start" justify="start">
                                                    <Heading level={Level.headline}>
                                                        {report &&
                                                            localCurrency(
                                                                report.total.revenue.amount,
                                                                (report.total.revenue?.unit ||
                                                                    defaultCurency) as Currency
                                                            )}
                                                    </Heading>
                                                    <div>
                                                        <Text type={TextType.body}>
                                                            {report?.total?.revenue?.unit}
                                                        </Text>
                                                        <Text
                                                            type={TextType.body}
                                                            color={theme.disabledColor}
                                                        >
                                                            {i18n.t(
                                                                vatLiable
                                                                    ? 'Reports.VatLiable'
                                                                    : 'Reports.VatNotLiable'
                                                            )}
                                                        </Text>
                                                    </div>
                                                </Composite>
                                            </>
                                        )}
                                    </div>
                                    {uiState === UIState.READY && (
                                        <div>
                                            <Composite gap={0} justify="end">
                                                {report && report.trend.revenue.amount >= 0 ? (
                                                    <Icon
                                                        size={16}
                                                        icon="arrowUp"
                                                        color={theme.successColor}
                                                    />
                                                ) : (
                                                    <Icon
                                                        size={16}
                                                        icon="arrowDown"
                                                        color={theme.errorColor}
                                                    />
                                                )}
                                                <Text
                                                    color={
                                                        report && report.trend.revenue.amount >= 0
                                                            ? theme.successColor
                                                            : theme.errorColor
                                                    }
                                                >
                                                    {report?.trend?.revenue?.amount.toFixed(2)}
                                                    {report?.trend?.revenue?.unit}
                                                </Text>
                                            </Composite>
                                            <Text
                                                type={TextType.descriptionSmall}
                                                color={theme.disabledColor}
                                            >
                                                {i18n.t('Reports.TrendDescription')}
                                            </Text>
                                            <Button
                                                size={Size.SMALL}
                                                variant={Variant.TEXT}
                                                fillParent={false}
                                                padding="0"
                                                onClick={() =>
                                                    generateLink({
                                                        variables: {
                                                            input: {
                                                                stripeAccountId,
                                                            },
                                                        },
                                                    })
                                                }
                                                iconAfter="externalLink"
                                                iconUseMargin
                                            >
                                                {i18n.t(
                                                    'PaymentMethodSettings.Stripe.ActivatedText'
                                                )}
                                            </Button>
                                        </div>
                                    )}
                                </Composite>
                            }
                        >
                            <ChartContainer>
                                <RevenueChart
                                    label={i18n.t('Reports.RevenueTitle')}
                                    id="Revenue"
                                    datalabels={
                                        report
                                            ? report.perDay.revenue.map(
                                                  (_: ReportPerDay, i: number) => (i + 1).toString()
                                              )
                                            : []
                                    }
                                    datapoints={
                                        report
                                            ? report.perDay.revenue.map(
                                                  (item: ReportPerDay) => item.amount
                                              )
                                            : []
                                    }
                                    dates={
                                        report
                                            ? report.perDay.revenue.map((item: ReportPerDay) =>
                                                  item.date.toString()
                                              )
                                            : []
                                    }
                                    unit={
                                        report
                                            ? report.perDay.revenue.find(
                                                  (item: ReportPerDay) =>
                                                      (item.unit && item.unit.length > 0) ?? false
                                              )?.unit ?? ''
                                            : ''
                                    }
                                />
                            </ChartContainer>
                        </Card>
                    )}
                </ChartTwoSectionWrapper>
                <Card
                    clickable={false}
                    size={Size.LARGE}
                    loading={uiState === UIState.LOADING}
                    error={uiState === UIState.ERROR}
                    errorButtonText={i18n.t('Reports.ReloadButtonText')}
                    errorButtonOnClick={getReportQuery}
                    alignChildren={uiState === UIState.READY ? 'end' : 'center'}
                    justifyChildren={uiState === UIState.ERROR ? 'center' : 'inherit'}
                    title={
                        <Composite align="start">
                            <Heading level={Level.h4}>
                                {i18n.t('Reports.ChargingSessionsTitle')}
                            </Heading>
                            {uiState === UIState.READY && (
                                <>
                                    <Button
                                        name="more-charging-sessions-button"
                                        size={Size.TINY}
                                        variant={Variant.SECONDARY}
                                        fillParent={false}
                                        iconAfter="more"
                                        onClick={(e?: MouseEvent) => {
                                            const { target } = e || ({} as MouseEvent);
                                            setMenuPositioningElement(
                                                menuPositioningElement
                                                    ? undefined
                                                    : (target as HTMLElement)
                                            );
                                        }}
                                    />
                                    <ContextWindow
                                        positioningElement={menuPositioningElement}
                                        onClosePress={() => {
                                            setMenuPositioningElement(undefined);
                                        }}
                                        show={!!menuPositioningElement}
                                        autoHeight
                                        side="left"
                                        bodyContent={
                                            <ChargingSessionsDataButon
                                                tid="charging-sessions-data-button"
                                                id={chargeSystemId}
                                                year={calendarDate.getFullYear().toString()}
                                                month={(calendarDate.getMonth() + 1).toString()}
                                                sessionsColumnNames={sessionsColumnNames}
                                                currency={defaultCurency as Currency}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </Composite>
                    }
                >
                    <Heading level={Level.h1}>{report?.total?.chargingSessions?.amount}</Heading>
                </Card>
                <Card
                    clickable={false}
                    size={Size.LARGE}
                    loading={uiState === UIState.LOADING}
                    error={uiState === UIState.ERROR}
                    errorButtonText={i18n.t('Reports.ReloadButtonText')}
                    errorButtonOnClick={getReportQuery}
                    alignChildren={uiState === UIState.READY ? 'end' : 'center'}
                    justifyChildren={uiState === UIState.ERROR ? 'center' : 'inherit'}
                    title={
                        <Heading level={Level.h4}>
                            {i18n.t('Reports.TotalOccupiedTimeTitle')}
                        </Heading>
                    }
                >
                    <Composite align="start">
                        <Heading level={Level.h1}>
                            {localDecimals(report?.total?.occupiedTime?.amount ?? 0, 100, 1)}
                        </Heading>
                        <Text>{report?.total?.occupiedTime?.unit}</Text>
                    </Composite>
                </Card>
                <Card
                    clickable={false}
                    size={Size.LARGE}
                    loading={uiState === UIState.LOADING}
                    error={uiState === UIState.ERROR}
                    errorButtonText={i18n.t('Reports.ReloadButtonText')}
                    errorButtonOnClick={getReportQuery}
                    alignChildren={uiState === UIState.READY ? 'end' : 'center'}
                    justifyChildren={uiState === UIState.ERROR ? 'center' : 'inherit'}
                    title={
                        <Composite align="start">
                            <Heading level={Level.h4}>
                                {i18n.t('Reports.OccupationRateTitle')}
                            </Heading>
                            <Button
                                size={Size.TINY}
                                variant={Variant.TERTIARY}
                                fillParent={false}
                                iconAfter="question"
                                tooltip={i18n.t('Reports.OccupationRateTooltip')}
                            />
                        </Composite>
                    }
                >
                    <Composite align="start">
                        <ProgressIndicator
                            progress={
                                report
                                    ? parseInt(report.total.occupationRate.amount.toFixed(), 10)
                                    : 0
                            }
                            type={ProgressType.DONUT}
                        />
                        <Heading level={Level.h1}>
                            {report?.total?.occupationRate?.amount.toFixed()}
                        </Heading>
                        <Text>{report?.total?.occupationRate?.unit}</Text>
                    </Composite>
                </Card>
                <TableSectionWrapper>
                    <Card
                        size={Size.LARGE}
                        clickable={false}
                        loading={uiState === UIState.LOADING}
                        error={uiState === UIState.ERROR}
                        errorButtonText={i18n.t('Reports.ReloadButtonText')}
                        errorButtonOnClick={getReportQuery}
                        alignChildren={uiState === UIState.READY ? 'start' : 'center'}
                        justifyChildren={uiState === UIState.READY ? 'start' : 'center'}
                        title={
                            <Composite>
                                <Heading level={Level.h4}>
                                    {i18n.t('Reports.ChargerUsageDetailsTitle')}
                                </Heading>
                                {uiState === UIState.READY && (
                                    <Button
                                        name="cud-download-csv-button"
                                        size={Size.TINY}
                                        variant={Variant.SECONDARY}
                                        fillParent={false}
                                        icon="arrowDown"
                                        iconUseMargin
                                        onClick={downloadChargerUsageCsv}
                                    >
                                        {i18n.t('Reports.DownloadCSVButton')}
                                    </Button>
                                )}
                            </Composite>
                        }
                    >
                        {report && chargerUsage.length > 0 && (
                            <SimpleTable<SessionItem>
                                items={chargerUsage.map((item: ChargerUsage) => ({
                                    id: item.alias,
                                    sessions: item.chargingSessions.amount,
                                    totalTime: `${localDecimals(
                                        item.occupiedTime.amount,
                                        100,
                                        1
                                    )} ${item.occupiedTime.unit}`,
                                    totalEnergy: `${localDecimals(item.energyConsumption.amount)} ${
                                        item.energyConsumption.unit
                                    }`,
                                    revenue: `${localCurrency(
                                        item.revenue.amount,
                                        item.revenue.unit || defaultCurency
                                    )}`,
                                }))}
                                columns={chargerUsageColumns}
                                columnNames={chargerUsageColumnNames}
                            />
                        )}
                    </Card>
                </TableSectionWrapper>
                <TableSectionWrapper>
                    <Card
                        size={Size.LARGE}
                        clickable={false}
                        loading={uiState === UIState.LOADING}
                        error={uiState === UIState.ERROR}
                        errorButtonText={i18n.t('Reports.ReloadButtonText')}
                        errorButtonOnClick={getReportQuery}
                        alignChildren={uiState === UIState.READY ? 'start' : 'center'}
                        justifyChildren={uiState === UIState.READY ? 'start' : 'center'}
                        title={
                            <Composite>
                                <Heading level={Level.h4}>
                                    {i18n.t('Reports.UserUsageDetailsTitle')}
                                </Heading>
                                {uiState === UIState.READY && (
                                    <Button
                                        name="cud-download-csv-button"
                                        size={Size.TINY}
                                        variant={Variant.SECONDARY}
                                        fillParent={false}
                                        icon="arrowDown"
                                        iconUseMargin
                                        onClick={downloadUserUsageCsv}
                                    >
                                        {i18n.t('Reports.DownloadCSVButton')}
                                    </Button>
                                )}
                            </Composite>
                        }
                    >
                        {report && userUsage.length > 0 && (
                            <SimpleTable<SessionItem>
                                items={userUsage.map((item: UserUsage) => ({
                                    id: item.user,
                                    user: item.user,
                                    phone: item.phone,
                                    sessions: item.chargingSessions.amount,
                                    totalTime: `${localDecimals(
                                        item.occupiedTime.amount,
                                        100,
                                        1
                                    )} ${item.occupiedTime.unit}`,
                                    totalEnergy: `${localDecimals(item.energyConsumption.amount)} ${
                                        item.energyConsumption.unit
                                    }`,
                                    revenue: `${localCurrency(
                                        item.revenue.amount,
                                        item.revenue.unit || defaultCurency
                                    )}`,
                                }))}
                                columns={userUsageColumns}
                                columnNames={userUsageColumnNames}
                            />
                        )}
                    </Card>
                </TableSectionWrapper>
                <TableSectionWrapper>
                    <Card
                        size={Size.LARGE}
                        clickable={false}
                        loading={uiState === UIState.LOADING}
                        error={uiState === UIState.ERROR}
                        errorButtonText={i18n.t('Reports.ReloadButtonText')}
                        errorButtonOnClick={getReportQuery}
                        alignChildren={uiState === UIState.READY ? 'start' : 'center'}
                        justifyChildren={uiState === UIState.READY ? 'start' : 'center'}
                        title={
                            <Composite>
                                <Heading level={Level.h4}>
                                    {i18n.t('Reports.VisitorRfidUsageDetailsTitle')}
                                </Heading>
                                {uiState === UIState.READY && (
                                    <Button
                                        name="cud-download-csv-button"
                                        size={Size.TINY}
                                        variant={Variant.SECONDARY}
                                        fillParent={false}
                                        icon="arrowDown"
                                        iconUseMargin
                                        onClick={downloadVisitorRfidUsageCsv}
                                    >
                                        {i18n.t('Reports.DownloadCSVButton')}
                                    </Button>
                                )}
                            </Composite>
                        }
                    >
                        {report && chargerUsage.length > 0 && (
                            <SimpleTable<SessionItem>
                                items={rfidUsage.map((item: VisitorRfidUsage) => ({
                                    id: item.keyId,
                                    keyId: item.keyId,
                                    description: item.description,
                                    sessions: item.chargingSessions.amount,
                                    totalTime: `${localDecimals(
                                        item.occupiedTime.amount,
                                        100,
                                        1
                                    )} ${item.occupiedTime.unit}`,
                                    totalEnergy: `${localDecimals(item.energyConsumption.amount)} ${
                                        item.energyConsumption.unit
                                    }`,
                                    revenue: '',
                                }))}
                                columns={visitorRfidUsageColumns}
                                columnNames={visitorRfidUsageColumnNames}
                            />
                        )}
                    </Card>
                </TableSectionWrapper>
            </Content>
        </Root>
    );
};
