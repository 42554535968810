import { AddressDetails } from '../address';
import { Admin, AdminInvite } from '../admin';
import { Subscription } from '../subscription';

export interface Organization {
    id?: string;
    name?: string;
    vatNr?: string;
    orgNr?: string;
    customerNr?: string;
    description?: string;
    invoiceEmail?: string;
    contactEmail?: string;
    contactPhone?: string;
    billingAddress?: AddressDetails;
    contactAddress?: AddressDetails;
    chargeSystems?: any[];
    invitedAdmins?: AdminInvite[];
    admins?: Admin[];
    owner?: string;
    category?: string;

    configurationStatus?: ConfigurationStatus;

    stripeAccountId?: string;
    stripeStatus?: StripeStatus;
    stripeAccount?: StripeAccount;

    vatLiable?: boolean;
    subscription?: Subscription;
}

export interface StripeAccount {
    email?: string;
    name?: string;
    status?: StripeAccountStatus;
}

export enum StripeStatus {
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
}

export enum StripeAccountStatus {
    NA = 'NA',
    INCOMPLETE = 'INCOMPLETE',
    COMPLETED = 'COMPLETED',
}

export enum ConfigurationStatus {
    COMPLETED = 'COMPLETED',
    SETUP_REQUIRED = 'SETUP_REQUIRED',
}

export enum CreationStatus {
    EXISTS = 'EXISTS',
    CREATED = 'CREATED',
    FAILED = 'FAILED',
}
