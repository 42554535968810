import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
            }
            connectors {
                id
                alias
            }
        }
    }
`;
